
import { defineComponent, watch } from 'vue';
import Plane from './Plane.vue';
export default defineComponent({
  components: { Plane },
  
  data() {
    return {
      page: "boarding"
    }
  },methods:{
    setRules() {
      this.page = "home";
    },

    setBoarding() {
      this.page = "boarding";
    }
  }
});
