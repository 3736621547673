
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import NavButton from '@/components/NavButton.vue';

export default defineComponent({
  components: { NavButton },
  setup() {
    const route = useRoute();
    const path = computed(() => route.path);

    const menu = [
      { name: 'Airline Rules', url: '/' },
      { name: 'Boarding Apes', url: '/' },
    ];

    return {
      menu,
      path,
    };
  },
});
